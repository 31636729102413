import React from 'react';
import { Route, Redirect } from 'react-router';
import {is_login } from '../helpers/Helper'

const UserRoute = ({component:Component,layout:Layout,...rest}) => (
    <Route
    {...rest}
    render={props=>(
        (is_login()) ? (
            <Layout>
                    <Component {...props} />
                </Layout>
            ) : <Redirect to="/login" />
            )}
            
    />
);

export default UserRoute;
