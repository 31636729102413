import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import { POST_ADD_EDIT_THEME, POST_GET_THEME_DETAIL } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import Select from 'react-select';
import {MultiSelectValueArray,arrayColumn} from '../../helpers/Helper';
var md5 = require('md5');

const AdminForm = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({});
    

    const FormOptions = [
        { value: 'cover_img', label: 'Cover Img' },
        { value: 'profile_img', label: 'Profile Img' },
        { value: 'contact_details', label: 'Contact Details' },
        { value: 'social_links', label: 'Social Links' },
        { value: 'more', label: 'More' },
        { value: 'profile', label: 'Profile' },
        { value: 'about', label: 'About' },
        { value: 'photos', label: 'Photos' },
        { value: 'contact_form', label: 'Contact Form' }
    ];
    const [selectedField, setSelectedField] = useState(null);

    const [title, setTitle] = useState('Add Theme');
    const [, forceUpdate] = useState();
 
    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                setTitle('Edit Theme');
                var res_data = await post(POST_GET_THEME_DETAIL, { 'theme_id': props.match.params.id }, {});
                if (res_data.status) {
                    setInputs(res_data.detail);
                    setSelectedField(await MultiSelectValueArray(FormOptions,res_data.detail.form_field))
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_THEME, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                history.push("/themes");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if(event.target.name === 'img' ){
            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = function(){
                setInputs(inputs => ({ ...form_inputs, [event.target.name]: reader.result }));
            }
            reader.readAsDataURL(file);
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    // input text change handler
    const handleSelectChange = (name,value) => {
        setInputs(inputs => ({ ...form_inputs, [name]: arrayColumn(value,'value').join() }));
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>TapGrid | {title}</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Theme Name</label>
                                            <input name="name" value={form_inputs.name} onChange={handleInputChange} className="form-control" type="text" />
                                            {simpleValidator.current.message('city name', form_inputs.name, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Slug</label>
                                            <input name="slug" value={form_inputs.slug} onChange={handleInputChange} className="form-control" type="text" />
                                            {simpleValidator.current.message('slug', form_inputs.name, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Select Field</label>
                                            <Select defaultValue={selectedField} isMulti onChange={handleSelectChange.bind(this,'form_field')} options={FormOptions} />
                                            {simpleValidator.current.message('Field', form_inputs.form_field, 'required')}
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Image</label>
                                            <div className='row'>
                                                <div className='col-sm-7'>
                                                    <input name="img" onChange={handleInputChange} className="form-control" type="file" accept="image/*"/>
                                                </div>
                                                <div className='col-sm-5'>
                                                    {form_inputs.image && <img src={form_inputs.image} width="100px"/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                    <Link to="/themes" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(AdminForm)
