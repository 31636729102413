import React, { useState, useEffect, useRef } from 'react';
import {withRouter } from "react-router-dom";
import {post,get} from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import {POST_ADD_EDIT_COUNTRI,POST_GET_COUNTRI_DETAIL} from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import {Helmet} from "react-helmet";
import { Link,useHistory } from "react-router-dom";

const ChangePassword = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({'country_id' : 0,'country_id' : ''});
    const [title, setTitle] = useState('Add Country');
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile () {
            if(props.match.params.id){
                setTitle('Edit Country');
                var res_data = await post(POST_GET_COUNTRI_DETAIL,{'country_id' : props.match.params.id},{});
                if (res_data.status) {
                    setInputs(res_data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

     // form submit event
     const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data =  await post(POST_ADD_EDIT_COUNTRI,form_inputs,{});
            if(res_data.status){
                toast(res_data.message);
                history.push("/country");
            }else{
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

     // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }


    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>  
            <Helmet>
                <meta charSet="utf-8" />
                <title>HS Pay | {title}</title>
            </Helmet>
            
            <div className='row mt-3'>
                <div className='col-sm-6'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label text-capitalize">Country Name</label>
                                    <input name="country_name" value={form_inputs.country_name} onChange={handleInputChange} className="form-control" type="text" />
                                    {simpleValidator.current.message('country name', form_inputs.country_name, 'required')}
                                </div>
                               
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                    <Link to="/country" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>    
    );
}
export default withRouter(ChangePassword)
