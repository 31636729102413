import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './layouts/App_route';
import UserRoute from './layouts/UserRoute';

import { BrowserRouter } from 'react-router-dom';

import Default from './layouts/Default';
import Full from './layouts/Full';

// pages
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Auth/Login';
import Logout from "./pages/Auth/Logout";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ChangePassword from "./pages/Auth/ChangePassword";

//Admin
import AdminList from "./pages/Admin/AdminList";
import AdminForm from "./pages/Admin/AdminForm";

//card
import CardList from "./pages/Card/CardList";
import CardForm from "./pages/Card/CardForm";

//Themes
import ThemesList from "./pages/Themes/ThemesList";
import ThemesForm from "./pages/Themes/ThemesForm";

//payment
import PaymentList from "./pages/Payment/PaymentList";
import SubscriptionList from "./pages/Payment/Subscription";

//users
import UsersList from "./pages/Users/UsersList";

//State
import CountriesList from "./pages/Countries/CountriesList";
import CountriesForm from "./pages/Countries/CountriesForm";

//State
import StatesList from "./pages/States/StatesList";
import StatesForm from "./pages/States/StatesForm";

//city
import CityList from "./pages/City/CityList";
import CityForm from "./pages/City/CityForm";

// setting
import Setting from "./pages/Setting/Setting";
import EmailSetting from "./pages/Setting/EmailSetting";
import ApiLogSetting from "./pages/Setting/ApiLogSetting";

//User Role
import UserRolesList from "./pages/UserRoles/UserRolesList";
import UserRolesForm from "./pages/UserRoles/UserRolesForm";

function App() {
    return (
        <>
        <BrowserRouter>
            <Switch>
                <AppRoute exact path="/login" component={Login} layout={Full} />
                <AppRoute exact path="/logout" component={Logout} layout={Full} />
                <AppRoute exact path="/forgot-password" component={ForgetPassword} layout={Full} />
                
                <UserRoute exact path="/change-password" component={ChangePassword} layout={Default} />

                {/* admin */}
                <UserRoute exact path="/admin" component={AdminList} layout={Default} />
                <UserRoute exact path="/admin/add" component={AdminForm} layout={Default} />
                <UserRoute exact path="/admin/edit/:id?" component={AdminForm} layout={Default} />
               
                {/* card */}
                <UserRoute exact path="/card" component={CardList} layout={Default} />
                <UserRoute exact path="/card/add" component={CardForm} layout={Default} />
                <UserRoute exact path="/card/edit/:id?" component={CardForm} layout={Default} />
                
                {/* ThemesList */}
                <UserRoute exact path="/themes" component={ThemesList} layout={Default} />
                <UserRoute exact path="/themes/add" component={ThemesForm} layout={Default} />
                <UserRoute exact path="/themes/edit/:id?" component={ThemesForm} layout={Default} />

                {/* PaymentList */}
                <UserRoute exact path="/payment" component={PaymentList} layout={Default} />
                <UserRoute exact path="/subscription" component={SubscriptionList} layout={Default} />


                {/* User */}
                <UserRoute exact path="/users" component={UsersList} layout={Default} />
                
                {/* user role */}
                <UserRoute exact path="/user-role" component={UserRolesList} layout={Default} />
                <UserRoute exact path="/user-role/add" component={UserRolesForm} layout={Default} />
                <UserRoute exact path="/user-role/edit/:id?" component={UserRolesForm} layout={Default} />

                {/* countries */}
                <UserRoute exact path="/country" component={CountriesList} layout={Default} />
                <UserRoute exact path="/country/add" component={CountriesForm} layout={Default} />
                <UserRoute exact path="/country/edit/:id?" component={CountriesForm} layout={Default} />
               
                {/* states */}
                <UserRoute exact path="/states" component={StatesList} layout={Default} />
                <UserRoute exact path="/states/add" component={StatesForm} layout={Default} />
                <UserRoute exact path="/states/edit/:id?" component={StatesForm} layout={Default} />

                {/* City */}
                <UserRoute exact path="/cities" component={CityList} layout={Default} />
                <UserRoute exact path="/cities/add" component={CityForm} layout={Default} />
                <UserRoute exact path="/cities/edit/:id?" component={CityForm} layout={Default} />
                

                {/* Setting */}
                <UserRoute exact path="/setting" component={Setting} layout={Default} />
                <UserRoute exact path="/setting/email" component={EmailSetting} layout={Default} />
                <UserRoute exact path="/setting/api-log" component={ApiLogSetting} layout={Default} />
                
                <UserRoute exact path="/" component={Dashboard} layout={Default} />
            </Switch>
        </BrowserRouter> 
    </>
  );
}

export default App;
